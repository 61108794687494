<template>
  <div>
    <main>
      <h1>404 Error</h1>
    </main>
  </div>
</template>

<script>
export default {
  name: '404',
  title: '404 Error',
  mounted () {
    this.config.loading = false
  }
}
</script>

<style scoped>

</style>
