<template>
  <div>
    <main class="content-500" id="profil">
      <section class="t-center pad-16">
        <h1 class="color-black">{{ user.firstname }}</h1>
        <h2 class="color-blue">Willkommen auf deinem Profil</h2>
      </section>
      <section id="profilheader">
        <img :src="config.projectURL + 'assets/images/users/' + user.avatar" class="image width-150 circular">
        <div class="profilheaderInfos">
          <router-link to="settings" class="bgcolor-second color-white button settingsbutton">
            <img src="assets/images/icons/settings-white.svg" class="width-24" style="filter: invert(1)">
          </router-link>
          <button @click="auth.logout()" class="button signOutButton bgcolor-blue color-white br-8">
            Logout
          </button>
        </div>
      </section>
      <section class="pad-16" id="friendsRequests" v-if="friendsRequests > 0" @click="friendsRequestModal = true;">
        <div class="bgcolor-second color-blue br-10 t-center pad-16">
          <p>{{ friendsRequests }} offene Anfragen</p>
        </div>
      </section>

    </main>
  </div>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "Profil",
  title: "Profil",
  components: {

  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {

    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {

  }
}
</script>

<style scoped>

</style>