import { createRouter, createWebHistory } from 'vue-router'

import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";

import profile from "@/views/profile";
import settings from "@/views/settings";
import login from "@/views/login";
import dashboard from "@/views/dashboard";
import register from "@/views/register";
import notfound from "@/views/404";
import navBar from "@/components/navBar/navBar";
import footer from "@/components/footer/footer";



const routes = [
  {
    path: '/',
    alias: '/login',
    name: 'Login',
    components: {
      default: login,
    },
    meta: { noAuth: true },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    components: {
      navBar: navBar,
      default: dashboard,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/register',
    name: 'Register',
    components: {
      default: register,
    },
    meta: { noAuth: true },
  },
  {
    path: '/profile',
    name: 'Profil',
    components: {
      navBar: navBar,
      default: profile,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/settings',
    name: 'Settings',
    components: {
      navBar: navBar,
      default: settings,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    name: 'notfound',
    path: '/:pathMatch(.*)*',
    alias: '/404',
    components: {
      default: notfound,
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = authStore()
  const config = configStore()

  config.menuModal = false

  window.scrollTo(0, 0)
  window.onscroll = function () {}

  if (to.matched.some(record => record.meta.auth)) {
    auth.update()
        .then(function (resolved) {
          config.loading = true
            next()
        })
        .catch(function (e) {
          next({
            path: '/login',
            query: { redirect: to.fullPath }
          })
        })
  } else if (to.matched.some(record => record.meta.noAuth)) {
    auth.update()
        .then(() => {
          next({
            name: 'Dashboard'
          })
        })
        .catch((e)=> {
          next()
        })
        .finally(function () {
          config.loading = true
        })
  } else {
    auth.update()
        .catch(e =>{
          console.log(e)
        })
        .finally(() => {
          config.loading = true
          next()
        })
  }
})

let history = []
router.afterEach((to, from) => {
  if(to.path === history.at(-2)?.path){
    to.meta.transition = history.at(-2).direction === 'slide-right' ? 'slide-left' : 'slide-right'
    history.pop()
  }else{
    to.meta.transition = to.path.split('/').length < from.path.split('/').length ? 'slide-right' : 'slide-left'
    history.push({path: to.path, direction: to.meta.transition})
  }
})

export default router
