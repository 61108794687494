<template>
  <div>
    <main>
    <section class="login" v-if="page === 'login'">
      <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign in to your account</h2>
        </div>

        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form class="space-y-6" @submit.prevent="login()" method="POST">
            <div>
              <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
              <div class="mt-2">
                <input v-model="email" id="email" name="email" type="email" autocomplete="email" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              </div>
            </div>

            <div>
              <div class="flex items-center justify-between">
                <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
                <div class="text-sm">
                  <a style="cursor: pointer;" @click="page = 'forgot'" class="font-semibold text-indigo-600 hover:text-indigo-500">Forgot password?</a>
                </div>
              </div>
              <div class="mt-2">
                <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              </div>
            </div>
            <div class="relative flex gap-x-3" v-if="!config.cordova">
              <div class="flex h-6 items-center">
                <input v-model="cookie" id="remember" name="remember" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600">
              </div>
              <div class="text-sm leading-6">
                <label for="remember" class="font-medium text-gray-900">remember me</label>
              </div>
            </div>
            <div>
              <span v-if="loginStatus === 'fail'" class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">Login fehlgeschlagen. Bitte überprüfe deine Angaben.</span>
            </div>
            <div>
              <button type="submit" class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign in</button>
            </div>
          </form>

          <p class="mt-10 text-center text-sm text-gray-500">
            Not a member?
            <router-link to="/register" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Sign Up now</router-link>
          </p>
        </div>
      </div>
    </section>
    <section class="login" v-if="page === 'forgot'">
      <div class="content-500">
        <div class="t-center pad-16">
          <h1 class="f-bold color-black">
            Passwort vergessen?
          </h1>
          <h2 class="color-blue f-normal">
            Bitte gib deine E-Mail-Adresse ein
          </h2>
        </div>
        <div class="t-center">
          <div class="field simple pad-8">
            <input class="color-black" type="email" name="email" placeholder="E-Mail-Adresse" required v-model="email">
          </div>
        </div>
        <p class="feedbackmessage t-center pad-8 color-blue" v-if="forgotStatus === 'ok'">
          Sofern dein Account mit dieser E-Mail verbunden ist, erhälst du eine E-Mail.
        </p>
        <div class="t-center pad-16 width-300">
          <button @click="forgotPassword" class="button fluid bgcolor-blue color-white circular" :disabled="resetLoading" :class="{disabled: resetLoading}">
            Passwort zurücksetzen
          </button>
          <div class="divider color-black">
            oder
          </div>
          <button type="button" class="button fluid bgcolor-black color-white circular" @click="page = 'login'">
            Zurück
          </button>
        </div>
      </div>
    </section>
    <section class="login" v-if="page === 'resetpassword'">
      <div class="content-500">
        <div class="t-center pad-32">
          <h1 class="f-normal">
            Neues Passwort erstellen
          </h1>
          <h2 class="color-blue">
            Bitte gib dein neues Passwort ein:
          </h2>
        </div>
        <div class="t-center">
          <div class="field simple pad-8">
            <input type="password" class="color-white" name="password" placeholder="Passwort" required v-model="pw1">
          </div>
          <div class="field simple pad-8">
            <input type="password" class="color-white" name="password2" placeholder="Passwort wiederholen" required v-model="pw2">
          </div>
          <p class="feedbackmessage t-center pad-8 color-petrol" v-if="resetStatus === 'ok'">
            Dein Passwort wurde erfolgreich geändert.
          </p>
          <p class="feedbackmessage t-center pad-8 color-blue" v-if="!passwordCheck && pw2.length > 0">
            Die Passwörter stimmen nicht überein oder sind nicht mindestens 8 Zeichen lang.
          </p>
          <p class="feedbackmessage t-center pad-8 color-blue" v-if="resetStatus === 'invalid'">
            Der Link ist abgelaufen. Bitte fordere einen neuen an.
          </p>
          <p class="feedbackmessage t-center pad-8 color-blue" v-if="resetStatus === 'password'">
            Ups, da lief was schief.
          </p>
        </div>
        <div class="t-center pad-16" v-if="resetStatus !== 'ok'">
          <button @click="resetPassword" class="button bgcolor-blue color-white circular" :disabled="!passwordCheck">
            Passwort speichern
          </button>
          <div class="divider">
            oder
          </div>
          <button type="button" class="button bgcolor-second color-white circular" @click="page = 'login'">
            Zurück
          </button>
        </div>
        <div class="t-center pad-16" v-else>
          <button @click="page = 'login'" class="button color-white circular">
            Zum Login
          </button>
        </div>
      </div>
    </section>
  </main>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";


export default {
  name: "Login",
  title: "Login",
  components: {
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      page: this.$route.query.page ?? 'login',
      token: this.$route.query.resetkey,
      cookie: this.config.cordova,
      email: '',
      password: '',
      pw1: '',
      pw2: '',
      loginStatus: '',
      forgotStatus: '',
      resetStatus: '',
      resetLoading: false
    }
  },
  computed:{
    passwordCheck(){
      return this.pw1.length > 7 && this.pw1 === this.pw2
    }
  },
  watch: {
    email(){
      this.resetLoading = false
    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    login(){
      const _this = this
      this.auth.login(this.email, this.password, this.cookie)
          .then((response) => {
            console.log(response)
            if(response === 250){
              _this.loginStatus = 'ok'
              if(_this.$route.query.redirect){
                _this.$router.push(_this.$route.query.redirect)
              }else{
                _this.$router.push('Dashboard')
              }
            }else if(response === 251){
              _this.loginStatus = 'user'
            }else if(response === 252){
              _this.loginStatus = 'password'
            }
          })
          .catch(() => {
            _this.loginStatus = 'fail'
          })
    },
    //reset password
    forgotPassword() {
      this.resetLoading = true
      const _this = this
      this.auth.resetPassword(this.email)
          .then((response) => {
            if(response === 250){
              _this.forgotStatus = 'ok'
            }else if(response === 251){
              _this.forgotStatus = 'email'
            }else if(response === 252){
              _this.forgotStatus = 'fail'
            }
          })
          .catch(() => {
            _this.forgotStatus = 'fail'
            _this.resetLoading = false
          })
    },
    //restore password
    resetPassword() {
      const _this = this
      this.auth.restorePassword(this.token, this.pw1)
          .then(r => {
            if(r === 250){
              _this.resetStatus = 'ok'
            }else if(r === 251){
              _this.resetStatus = 'invalid'
            }else if(r === 252){
              _this.resetStatus = 'password'
            }
          })
          .catch(() => {
            _this.resetStatus = 'password'
          })
    }
  }
}
</script>

<style scoped>

</style>