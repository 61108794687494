<template>
  <div>
      <router-view name="navBar" />
      <router-view v-slot="{Component, route}">
          <transition :name="route.meta.transition" mode="out-in">
              <component :is="Component" :key="route.path" />
          </transition>
      </router-view>
      <router-view name="footer" />
  </div>
</template>

<script>
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {authStore} from "@/store/authStore";
import {notificationStore} from "@/store/notificationStore";

export default {
    name: '20NorthMVC',
    setup(){
        const config = configStore()
        const user = userStore()
        const notifications = notificationStore()
        const auth = authStore()
        window.projectURL = config.projectURL
        return {config, user, notifications, auth}
    },
    data () {
        return {
        }
    },
    watch: {
    },
    mounted () {
        const _this = this
        setInterval(()=>{
            _this.config.now = Date.now()
        },1000)

        this.notifications.loadNotification()
    },
    methods: {

    }
}
</script>

<style lang="css">

</style>
