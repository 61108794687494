<template>
  <div>
    <main class="content-500">
      <h1 class="text-3xl font-bold underline">
        Hello world! Test dd{{config.darkMode}}
      </h1>
      <h1>Hey {{user.firstname}}</h1>
      <div class="bg-white dark:bg-black">
        Test
      </div>
    </main>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {notificationStore} from "@/store/notificationStore";

export default {
  name: "Dashboard",
  title: "Dashboard",
  components: {

  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const notification = notificationStore()
    const user = userStore()

    return {auth, config, notification, user}
  },
  data(){
    return {

    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {


  }
}
</script>

<style scoped>

</style>